<template>
  <div>
    <b-card no-body>
      <h4 class="m-2">
        User Validation
      </h4>
    </b-card>

    <!-- Filters -->

    <b-card no-body>
      <div class="m-2">
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('common.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <label>{{ $tc('common.entries', perPage) }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-right justify-content-end">
              <b-form-input
                v-model="searchQuery"
                debounce="500"
                class="d-inline-block mr-1"
                :placeholder="$t('common.search') + '...'"
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserValidationListTable"
        :items="fetchUserValidations"
        responsive
        :fields="translatedTableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('message.empty')"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        no-sort-reset
      >
        <template #table-busy>
          <div class="text-center">
            <b-spinner variant="primary" label="Text Centered" />
          </div>
        </template>

        <!-- Column: name -->
        <template #cell(name)="data">
          <span class="text-capitalize">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Email -->
        <template #cell(email)="data">
          <span class="text-capitalize">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge pill variant="light-info" class="text-capitalize">
            {{ data.value }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
            dropup
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'master-user-validation-view',
                params: { id: data.item._id },
              }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t('common.details') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $tc('common.showing', dataMeta.of, {
                first: dataMeta.from,
                last: dataMeta.to,
                total: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUser"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import 'animate.css'
import useUserValidation from './useUserValidation'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BSpinner,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  computed: {
    translatedTableColumns() {
      return this.tableColumns.map(column => ({
        ...column,
        label: this.$t(`userValidation.tableHeader.${column.key}`),
      }))
    },
  },

  setup() {
    const USER_MASTER_STORE_MODULE_NAME = 'master-user'

    // Register module
    if (!store.hasModule(USER_MASTER_STORE_MODULE_NAME))
      store.registerModule(USER_MASTER_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_MASTER_STORE_MODULE_NAME))
        store.unregisterModule(USER_MASTER_STORE_MODULE_NAME)
    })

    // const isAddNewUserModalShow = ref(false)
    // const isApproveUserModalShow = ref(false)
    // const selectedUser = ref(null)

    // const openEditUserModal = data => {
    //   selectedUser.value = data
    //   isApproveUserModalShow.value = true
    // }

    const {
      refUserValidationListTable,
      tableColumns,
      perPage,
      currentPage,
      totalUser,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      fetchUserValidations,
      refetchData,
    } = useUserValidation()

    return {
      refUserValidationListTable,
      tableColumns,
      perPage,
      currentPage,
      totalUser,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      fetchUserValidations,
      refetchData,

      // openEditUserModal,
      // isAddNewUserModalShow,
      // isApproveUserModalShow,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.user-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
